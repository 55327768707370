var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var _a, _WebSdkApi_child, _WebSdkApi_iframe, _WebSdkApi_should_show_ui, _WebSdkApi_cancellable, _WebSdkApi_emitter;
import Emittery from "emittery";
import { decodeHashFromBase64 } from '@holochain/client';
const TESTING = global.COMB !== undefined;
if (!TESTING) {
    if (typeof window !== "undefined")
        window.COMB = require('@holo-host/comb').COMB;
}
function makeUrlAbsolute(url) {
    return new URL(url, window.location.href).href;
}
/**
 * A `WebSdkApi` is a connection to a Chaperone iframe containing Holo's client logic.
 * @param child - The child process connecting to Chaperone that is being monitored.
 */
class WebSdkApi {
    constructor(child) {
        // Private constructor. Use `connect` instead.
        _WebSdkApi_child.set(this, void 0);
        _WebSdkApi_iframe.set(this, void 0);
        _WebSdkApi_should_show_ui.set(this, void 0);
        _WebSdkApi_cancellable.set(this, void 0);
        _WebSdkApi_emitter.set(this, new Emittery());
        this._setShouldShowUI = should_show_ui => {
            // Without this check, we call history.back() too many times and end up exiting the UI
            if (__classPrivateFieldGet(this, _WebSdkApi_should_show_ui, "f") === should_show_ui) {
                return;
            }
            __classPrivateFieldSet(this, _WebSdkApi_should_show_ui, should_show_ui, "f");
            if (should_show_ui) {
                if (__classPrivateFieldGet(this, _WebSdkApi_cancellable, "f")) {
                    history.pushState('_web_sdk_shown', '');
                }
                __classPrivateFieldGet(this, _WebSdkApi_iframe, "f").style.display = 'block';
            }
            else {
                if (__classPrivateFieldGet(this, _WebSdkApi_cancellable, "f")) {
                    if (history.state === '_web_sdk_shown') {
                        history.back();
                    }
                }
                __classPrivateFieldGet(this, _WebSdkApi_iframe, "f").style.display = 'none';
            }
        };
        this.appInfo = () => __classPrivateFieldGet(this, _WebSdkApi_child, "f").call('appInfo');
        this.networkInfo = (args) => __classPrivateFieldGet(this, _WebSdkApi_child, "f").call('networkInfo', args);
        this.callZome = (args) => __awaiter(this, void 0, void 0, function* () { return __classPrivateFieldGet(this, _WebSdkApi_child, "f").call('callZome', args).then(unwrap); });
        this.createCloneCell = (args) => __classPrivateFieldGet(this, _WebSdkApi_child, "f").call('createCloneCell', args).then(unwrap);
        this.disableCloneCell = (args) => __classPrivateFieldGet(this, _WebSdkApi_child, "f").call('disableCloneCell', args).then(unwrap);
        this.enableCloneCell = (args) => __classPrivateFieldGet(this, _WebSdkApi_child, "f").call('enableCloneCell', args).then(unwrap);
        this.provideMemproofs = (args) => __classPrivateFieldGet(this, _WebSdkApi_child, "f").call('provideMemproofs', args).then(unwrap);
        this.signPayload = (args) => __classPrivateFieldGet(this, _WebSdkApi_child, "f").call('signPayload', args).then(unwrap);
        this.stateDump = () => __classPrivateFieldGet(this, _WebSdkApi_child, "f").call('stateDump');
        /*
        Triggers a request to show the credentials form
        and start on the "Create Credentials" page.
        
        The returned promise resolves as soon as the iframe (Chaperone)
        has received the request.
        
        If cancellable == true, then the form will have a close button
        and if the user clicks it or presses the back arrow,
        the credentials overlay will close.
        */
        this.signUp = (opts) => __awaiter(this, void 0, void 0, function* () {
            const { cancellable = true } = opts || {};
            __classPrivateFieldSet(this, _WebSdkApi_cancellable, cancellable, "f");
            yield __classPrivateFieldGet(this, _WebSdkApi_child, "f").call('signUp', opts);
        });
        /*
        Same as signUp, but starts on the "Login" page instead.
       */
        this.signIn = (opts) => __awaiter(this, void 0, void 0, function* () {
            const { cancellable = true } = opts || {};
            __classPrivateFieldSet(this, _WebSdkApi_cancellable, cancellable, "f");
            yield __classPrivateFieldGet(this, _WebSdkApi_child, "f").call('signIn', opts);
        });
        this.signOut = () => __classPrivateFieldGet(this, _WebSdkApi_child, "f").run('signOut');
        __classPrivateFieldSet(this, _WebSdkApi_child, child, "f");
        child.msg_bus.on('signal', (signal) => __classPrivateFieldGet(this, _WebSdkApi_emitter, "f").emit('signal', signal));
        child.msg_bus.on('agent-state', (agent_state) => {
            this.agentState = agent_state;
            this.myPubKey = decodeHashFromBase64(agent_state.id);
            __classPrivateFieldGet(this, _WebSdkApi_emitter, "f").emit('agent-state', this.agentState);
        });
        child.msg_bus.on('ui-state', (ui_state) => {
            this.uiState = ui_state;
            this._setShouldShowUI(ui_state.isVisible);
            __classPrivateFieldGet(this, _WebSdkApi_emitter, "f").emit('ui-state', this.uiState);
        });
        child.msg_bus.on('chaperone-state', (chaperone_state) => {
            this.chaperoneState = chaperone_state;
            __classPrivateFieldGet(this, _WebSdkApi_emitter, "f").emit('chaperone-state', this.chaperoneState);
        });
    }
    on(eventName, listener) {
        return __classPrivateFieldGet(this, _WebSdkApi_emitter, "f").on(eventName, listener);
    }
}
_a = WebSdkApi, _WebSdkApi_child = new WeakMap(), _WebSdkApi_iframe = new WeakMap(), _WebSdkApi_should_show_ui = new WeakMap(), _WebSdkApi_cancellable = new WeakMap(), _WebSdkApi_emitter = new WeakMap();
/**
 * The `static connect` function is a helper function that connects to the Chaperone server and returns a WebSdkApi object
 * @param []
 * - chaperoneUrl: The URL of the Chaperone server.
 * - authFormCustomization: The optional app customizations to display when authorizing the user
 * @returns The `connect` function returns a `WebSdkApi` object.
 */
WebSdkApi.connect = ({ chaperoneUrl, authFormCustomization: authOpts = {} }) => __awaiter(void 0, void 0, void 0, function* () {
    const url = new URL(chaperoneUrl || 'https://chaperone.holo.hosting');
    url.searchParams.set('websdk_version', process.env.VERSION);
    if (authOpts !== undefined) {
        if (authOpts.logoUrl !== undefined) {
            url.searchParams.set('logo_url', makeUrlAbsolute(authOpts.logoUrl));
        }
        if (authOpts.cssUrl !== undefined) {
            url.searchParams.set('css_url', makeUrlAbsolute(authOpts.cssUrl));
        }
        if (authOpts.appName !== undefined) {
            url.searchParams.set('app_name', authOpts.appName);
        }
        if (authOpts.membraneProofServer !== undefined) {
            url.searchParams.set('membrane_proof_server_url', makeUrlAbsolute(authOpts.membraneProofServer.url));
            url.searchParams.set('membrane_proof_server_payload', JSON.stringify(authOpts.membraneProofServer.payload));
        }
        if (authOpts.requireRegistrationCode !== undefined) {
            url.searchParams.set('require_registration_code', String(authOpts.requireRegistrationCode));
        }
        if (authOpts.integrationTestMode !== undefined) {
            url.searchParams.set('integration_test_mode', String(authOpts.integrationTestMode));
        }
        if (authOpts.allowEmailPasswordAuth !== undefined) {
            url.searchParams.set('allow_email_password_auth', String(authOpts.allowEmailPasswordAuth));
        }
        // INTERNAL OPTION
        // anonymous_allowed is barely implemented in Chaperone, and is subject to change,
        // so exposing this in the documentation is misleading.
        // This is currently useful for some special hApps that can't support an anonymous instance.
        if (authOpts.anonymousAllowed !== undefined) {
            url.searchParams.set('anonymous_allowed', String(authOpts.anonymousAllowed));
        }
    }
    let child;
    try {
        child = yield (window.COMB || global.COMB).connect(url.href, authOpts.container || document.body, 60000);
    }
    catch (err) {
        if (err.name === 'TimeoutError')
            console.log('Chaperone did not load properly. Is it running?');
        throw err;
    }
    const webSdkApi = new WebSdkApi(child);
    // Set styles and history props when in production mode
    // Note: Set styles and history props only when in production mode
    if (!TESTING) {
        __classPrivateFieldSet(webSdkApi, _WebSdkApi_iframe, document.getElementsByClassName('comb-frame-0')[0], "f");
        __classPrivateFieldGet(webSdkApi, _WebSdkApi_iframe, "f").setAttribute('allowtransparency', 'true');
        const style = __classPrivateFieldGet(webSdkApi, _WebSdkApi_iframe, "f").style;
        style.zIndex = '99999999';
        style.width = '100%';
        style.height = '100%';
        style.position = 'absolute';
        style.top = '0';
        style.left = '0';
        style.display = 'none';
        style.border = 'none';
        window.addEventListener('popstate', event => {
            if (event.state === '_web_sdk_shown') {
                history.back();
            }
            else {
                __classPrivateFieldGet(webSdkApi, _WebSdkApi_iframe, "f").style.display = 'none';
            }
        });
    }
    // Chaperone either returns agent_state and happ_id (success case)
    // or error_message
    const { error_message, chaperone_state, happ_id } = yield child.call('handshake');
    if (error_message) {
        __classPrivateFieldGet(webSdkApi, _WebSdkApi_iframe, "f").style.display = 'none';
        throw new Error(error_message);
    }
    const { agentState, uiState } = chaperone_state;
    webSdkApi.agentState = agentState;
    webSdkApi.myPubKey = agentState ? decodeHashFromBase64(agentState.id) : undefined;
    webSdkApi.uiState = uiState;
    webSdkApi.chaperoneState = chaperone_state;
    webSdkApi.happId = happ_id;
    return webSdkApi;
});
export default WebSdkApi;
function unwrap(result) {
    switch (result.type) {
        case 'ok':
            return result.data;
        case 'error':
            throw new Error(result.data);
        default:
            throw new Error(`Unrecognized result type: ${result.type}`);
    }
}
